<svelte:options
  customElement={{
    tag: "oc-text-field-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      delegateFocus: true,
      formAssociated: true,
      inputBehavior: true,
    }),
    props: {
      type: { type: "String" },
      placeholder: { type: "String" },
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" }, /*                                     */
      hint: { type: "String" },
      validationMessage: { type: "String", attribute: "validation-message" },
      pattern: { type: "String" },
      variant: { type: "String" },
      autocomplete: { type: "String" },
      required: { type: "Boolean" },
      minlength: { type: "Number" },
      maxlength: { type: "Number" },
      hideCounter: { type: "Boolean", attribute: "hide-counter" },
      prefixText: { type: "String", attribute: "prefix-text" },
      suffixText: { type: "String", attribute: "suffix-text" },
      prefixIcon: { type: "String", attribute: "prefix-icon" },
      suffixIcon: { type: "String", attribute: "suffix-icon" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import type { FullAutoFill } from "svelte/elements";
  import {
    ariaDescriptionWithValidation,
    implicitSubmit,
    refireNonComposableNativeEvent,
    stopLabelClickPropagation,
  } from "../../../common/actions";
  import CounterV1 from "../../../common/components/CounterV1.svelte";
  import { FormLabelIconV1 } from "../../../common/components/FormLabelIcon";
  import HintV1 from "../../../common/components/HintV1.svelte";
  import ValidationMessageV1 from "../../../common/components/ValidationMessageV1.svelte";

  import type { Props } from "./TextFieldV1.types";

  let {
    name = undefined,
    value = "",
    hint = undefined,
    validationMessage = undefined,
    hideDetails = false,
    variant = "default",
    required = false,
    disabled = false,
    pattern = undefined,
    autocomplete = "off",
    ocAriaLabel = undefined,
    placeholder = undefined,
    type = "text",
    minlength = undefined,
    maxlength = undefined,
    hideCounter = false,
    prefixText = undefined,
    suffixText = undefined,
    prefixIcon = undefined,
    suffixIcon = undefined,
    internals,
  }: Props & {
    internals: ElementInternals;
    hideDetails?: boolean;
  } = $props();

  const Host = $host();

  export function resetForm() {
    /*                            */
    value = Host.getAttribute("value") || "";
  }

  let inputElement = $state<HTMLInputElement>();

  /*                                    */
  const onInput = () => {
    value = inputElement!.value;
  };

  /*                                          */
  const beforeInput = (event: InputEvent) => {
    if (event.data) {
      if (type === "decimal" && !/^[0-9.,]*$/.test(event.data)) {
        event.preventDefault();
      } else if (type === "integer" && !/^\d*$/.test(event.data)) {
        event.preventDefault();
      }
    }
  };

  /*                                                                          */
  let nonEmptyPlaceholder = $derived(placeholder || " ");
  /*                             */
  let nativeInputType = $derived(["integer", "decimal"].includes(type) ? "number" : type);
  let showCounter = $derived(typeof (minlength ?? maxlength) === "number" && !hideCounter);
  let hasDetails = $derived(validationMessage || hint || showCounter);
  let computedVariant = $derived(validationMessage ? "error" : variant);

  $effect(() => {
    internals.setFormValue(value);
  });
</script>

<label
  class="text-field"
  class:text-field--disabled={disabled}
  class:text-field--error={computedVariant === "error"}
  class:text-field--has-value={value}
  class:text-field--success={computedVariant === "success"}
  class:text-field--warning={computedVariant === "warning"}
  use:stopLabelClickPropagation
>
  <input
    {autocomplete}
    bind:this={inputElement}
    class="text-field__input"
    {disabled}
    id="input"
    {maxlength}
    {minlength}
    {name}
    use:refireNonComposableNativeEvent={Host}
    use:implicitSubmit={internals}
    use:ariaDescriptionWithValidation={{ validationMessage, hint }}
    onbeforeinput={beforeInput}
    oninput={onInput}
    {pattern}
    placeholder={nonEmptyPlaceholder}
    type={nativeInputType}
    {value}
    aria-required={required}
    spellcheck="false"
    autocorrect="off"
  />

  {#if prefixIcon}
    <oc-icon-v1 class="text-field__prefix-icon" type={prefixIcon}></oc-icon-v1>
  {/if}

  {#if prefixText}
    <small class="text-field__prefix-text">{prefixText}</small>
  {/if}

  {#if suffixText}
    <small class="text-field__suffix-text">{suffixText}</small>
  {/if}

  {#if suffixIcon}
    <oc-icon-v1 class="text-field__suffix-icon" type={suffixIcon}></oc-icon-v1>
  {/if}

  <span class="text-field__label" aria-label={ocAriaLabel}>
    <slot />
    <FormLabelIconV1 variant={computedVariant} />
  </span>
</label>

{#if !hideDetails && hasDetails}
  <div class="text-field__details">
    <div>
      <ValidationMessageV1 {validationMessage} />
      <HintV1 {hint} {disabled} />
    </div>
    <CounterV1 counterValue={value} maxCounter={maxlength} minCounter={minlength} {disabled} />
  </div>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  $autofill-background-color: #f0f4fc;
  $transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  $gap: 8px;

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  .text-field {
    box-sizing: border-box;
    position: relative;
    display: grid;
    grid-template-areas: "prefix-icon prefix-text input suffix-text suffix-icon";
    grid-template-columns: auto auto 1fr auto auto auto;
    align-items: center;

    padding: tokens.$oc-component-form-field-spacing-y tokens.$oc-component-form-field-spacing-x;

    background: tokens.$oc-component-form-background-color;
    outline: 1px solid tokens.$oc-component-form-default-border-color;
    border-radius: tokens.$oc-component-form-field-border-radius;
    cursor: text;

    &:hover:not(.text-field--disabled) {
      outline-width: 2px;
    }

    &:focus-within {
      outline-width: 2px;

      &:not(.text-field--error):not(.text-field--warning):not(.text-field--success) {
        outline-color: tokens.$oc-component-form-default-border-color-focus;
      }
    }

    &__input {
      all: unset;
      grid-area: input;

      width: 100%;

      font: tokens.$oc-component-form-field-input-font;
      color: tokens.$oc-component-form-field-input-color;

      &::placeholder {
        font: tokens.$oc-component-form-field-placeholder-font;
        color: tokens.$oc-component-form-field-placeholder-color;
        opacity: 0;
        transition: $transition;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        box-sizing: border-box;
      }
    }

    &__label {
      position: absolute;
      top: tokens.$oc-component-form-field-spacing-y;
      left: tokens.$oc-semantic-spacing-50;
      padding: 0 tokens.$oc-semantic-spacing-25;
      max-width: calc(100% - 2 * tokens.$oc-semantic-spacing-50);
      box-sizing: border-box;

      transition: $transition;

      font: tokens.$oc-component-form-label-font;
      background-color: tokens.$oc-component-form-background-color;
      border-radius: tokens.$oc-component-form-field-label-border-radius;
      color: tokens.$oc-component-form-default-label-color;

      /*                            */
      display: flex;
      align-items: center;
      gap: tokens.$oc-component-form-state-icon-gap-x;

      > slot {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        user-select: none;
        cursor: text;
      }
    }

    &__details {
      display: flex;
      gap: 4px;
      justify-content: space-between;
      width: 100%;
    }

    &--success {
      outline-color: tokens.$oc-component-form-success-border-color;

      .text-field__label {
        color: tokens.$oc-component-form-success-label-color;
      }
    }

    &--warning {
      outline-color: tokens.$oc-component-form-warning-border-color;

      .text-field__label {
        oc-icon-v1 {
          color: tokens.$oc-semantic-color-text-secondary;
        }
      }
    }

    &--error {
      outline-color: tokens.$oc-component-form-error-border-color;

      .text-field__label {
        color: tokens.$oc-component-form-error-label-color;
      }
    }

    &--disabled {
      cursor: default;
      outline-color: tokens.$oc-component-form-disabled-border-color;
      background-color: tokens.$oc-component-form-disabled-background-color;

      .text-field__input {
        color: tokens.$oc-component-form-disabled-input-color;
      }

      .text-field__label {
        cursor: default;
        background-color: tokens.$oc-component-form-disabled-background-color;
        color: tokens.$oc-component-form-disabled-label-color;
      }
    }

    .text-field__prefix-icon {
      grid-area: prefix-icon;
    }

    .text-field__prefix-text {
      grid-area: prefix-text;
    }

    .text-field__suffix-icon {
      grid-area: suffix-icon;
    }

    .text-field__suffix-text {
      grid-area: suffix-text;
    }

    .text-field__prefix-text,
    .text-field__prefix-icon {
      transition: $transition;
      opacity: 0;
      margin-right: $gap;
    }

    .text-field__suffix-text,
    .text-field__suffix-icon {
      transition: $transition;
      opacity: 0;
      margin-left: $gap;
    }

    .text-field__prefix-text,
    .text-field__suffix-text {
      font: tokens.$oc-component-form-field-input-font;
      user-select: none;
    }

    /*                                          */

    @mixin enforceSeparateSelectors {
      &__input:autofill {
        @content;
      }
      &__input:-webkit-autofill {
        @content;
      }
      &:active:not(.text-field--disabled) .text-field__input {
        @content;
      }
      &:focus-within .text-field__input {
        @content;
      }
      &__input:not(:placeholder-shown) {
        @content;
      }
    }

    /**
 *
 *
 *
 */

    @include enforceSeparateSelectors {
      &::placeholder,
      ~ .text-field__suffix-icon,
      ~ .text-field__suffix-text,
      ~ .text-field__prefix-icon,
      ~ .text-field__prefix-text {
        opacity: 1;
      }

      ~ .text-field__label {
        top: -0.5rem;
        left: tokens.$oc-component-form-field-floating-label-spacing-left;
        padding: 0 tokens.$oc-component-form-field-floating-label-inner-spacing-x;
        font: tokens.$oc-component-form-field-floating-label-font;

        /*                                                             */
        gap: 4px;
      }
    }

    /*                           */
    &__input {
      &:autofill {
        -webkit-box-shadow: 0 0 0 50px $autofill-background-color inset;
        -webkit-text-fill-color: tokens.$oc-component-form-field-input-color;
      }
    }

    /*                                 */
    /*                                                                   */
    &__input {
      /*                             */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /*         */
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }

    /*                                                                                 */
    &:has(.text-field__input:autofill) {
      background: $autofill-background-color;
    }
  }
</style>

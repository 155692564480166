<svelte:options
  customElement={{
    tag: "oc-radio-button-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      formAssociated: true,
      delegateFocus: true,
      inputBehavior: true,
    }),
    props: {
      name: { type: "String", reflect: true }, /*                                    */
      labelPlacement: { type: "String", attribute: "label-placement" },
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      variant: { type: "String" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { useSlots } from "../../../common/utils/useSlots.svelte";
  import {
    ariaDescriptionWithValidation,
    implicitSubmit,
    refireNonComposableNativeEvent,
    singleSelection,
    stopLabelClickPropagation,
  } from "../../../common/actions";
  import { FormLabelIconV1 } from "../../../common/components/FormLabelIcon";

  import type { Props } from "./RadioButtonV1.types";

  let {
    name = undefined,
    labelPlacement = undefined,
    value = "on",
    checked = false,
    variant = "default",
    disabled = false,
    ocAriaLabel = undefined,
    hint = undefined,
    validationMessage = undefined,
    internals,
  }: Props & {
    internals: ElementInternals;
    hint?: string;
    validationMessage?: string;
  } = $props();

  const Host = $host();

  const slots = useSlots(Host);

  export function resetForm() {
    /*                            */
    checked = Host.hasAttribute("checked");
  }

  let computedVariant = $derived(validationMessage ? "error" : variant);

  $effect(() => {
    internals.setFormValue(checked ? value : null);
  });

  const onChange = (event: Event) => {
    const input = event.target as HTMLInputElement;
    if (!event.defaultPrevented) checked = input.checked;
  };
</script>

<label class="radio" class:radio--error={computedVariant === "error"} use:stopLabelClickPropagation>
  <!-- Left Label Placement -->
  {#if slots.default && labelPlacement === "left"}
    <span class="radio__label">
      <slot />
      <FormLabelIconV1 variant={computedVariant} />
    </span>
  {/if}

  <span>
    <input
      type="radio"
      class="radio__input"
      {checked}
      {name}
      {disabled}
      use:singleSelection={{ Host, name, checked }}
      use:refireNonComposableNativeEvent={Host}
      use:implicitSubmit={internals}
      use:ariaDescriptionWithValidation={{ validationMessage, hint }}
      onchange={onChange}
      aria-label={ocAriaLabel}
    />
    <!-- The box displays the outline and the icon. This is needed because input element doesn't support pseudo elements. -->
    <span class="radio__circle"></span>
  </span>

  <!-- Right Label Placement -->
  {#if slots.default && labelPlacement !== "left"}
    <span class="radio__label">
      <slot />
      <FormLabelIconV1 variant={computedVariant} />
    </span>
  {/if}
</label>

<style lang="scss" global>
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  .radio {
    display: flex;
    align-items: flex-start;
    gap: tokens.$oc-component-form-radio-button-gap-x;

    color: tokens.$oc-component-form-default-label-color;
    font: tokens.$oc-component-form-field-input-font;
  }

  .radio__input {
    all: unset;
    position: absolute;
    width: tokens.$oc-component-form-radio-button-container-size;
    height: tokens.$oc-component-form-radio-button-container-size;

    /*                 */
    & {
      @include mixins.focus-styles(50%);
    }
  }

  .radio__label {
    flex: 1;
  }

  .radio__circle {
    position: relative;
    display: block;
    width: tokens.$oc-component-form-radio-button-container-size;
    height: tokens.$oc-component-form-radio-button-container-size;

    outline: 1px solid tokens.$oc-component-form-default-border-color;
    border-radius: 50%;

    background-color: tokens.$oc-component-form-background-color;
  }

  /*                   */
  .radio__input:checked ~ .radio__circle:before {
    $center-dot: 7px;

    content: "";
    display: block;
    position: absolute;
    left: $center-dot;
    top: $center-dot;
    bottom: $center-dot;
    right: $center-dot;

    border-radius: 50%;
    background-color: tokens.$oc-component-form-default-icon-color;
  }

  /*                 */
  .radio--error {
    color: tokens.$oc-component-form-error-label-color;

    .radio__circle {
      outline-color: tokens.$oc-component-form-error-border-color;
    }

    .radio__input:checked ~ .radio__circle:before {
      background: tokens.$oc-component-form-error-icon-color;
    }
  }

  /*                 */
  @media (hover: hover) {
    :host(:hover:enabled) {
      .radio {
        cursor: pointer;
      }

      .radio__circle {
        outline-width: tokens.$oc-semantic-focus-outline-offset;
      }
    }
  }

  /*                  */
  :host(:enabled) {
    .radio__input:active ~ .radio__circle {
      outline-width: tokens.$oc-semantic-focus-outline-offset;
    }

    .radio:not(.radio--error) .radio__input:active ~ .radio__circle {
      outline-color: tokens.$oc-component-form-default-border-color-active;
    }
  }

  /*                    */
  :host(:disabled) {
    .radio {
      color: tokens.$oc-component-form-disabled-label-color;
    }

    .radio__circle {
      outline-color: tokens.$oc-component-form-disabled-border-color;
      background-color: tokens.$oc-component-form-disabled-background-color;

      &:before {
        background: tokens.$oc-component-form-disabled-icon-color;
      }
    }
  }
</style>

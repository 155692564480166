import { derived, get, readonly, writable } from "svelte/store";
import {
  CLOSE_TYPES,
  CLOSED_PROGRAMMATICALLY,
  CLOSED_WITH_ESCAPE,
  type CloseTypes,
} from "./events.svelte";
import { applyProps, sheetProps, sheetScope } from "./utils";
import type { Props } from "../SheetV1.types";

const historyLog = /*           */ sheetScope.scope("history");
const historyUseLog = /*           */ historyLog.scope("use");

/**
 *
 *
 */
const sheetHistoryStack = writable<string[]>([]);
if (import.meta.env.DEV) {
  sheetHistoryStack.subscribe((_) => historyLog.info("sheet history stack change", _));
}

/**
 *
 */
export const isSheetOpen = derived(sheetHistoryStack, (history) => history.length > 0);

/**
 *
 *
 */
const openSheetInstance = writable<HTMLOcSheetV1Element | null>(null);
/**
 *
 */
export const openSheet = readonly(openSheetInstance);
if (import.meta.env.DEV) {
  openSheet.subscribe((_) => historyLog.info("sheet instance change", _));
}

/**
 *
 */
export const openSheetProps = derived(openSheetInstance, (sheet) => {
  return sheet ? (Object.fromEntries(sheetProps.map((p) => [p, sheet[p]])) as Props) : null;
});
if (import.meta.env.DEV) {
  openSheetProps.subscribe((_) => historyLog.info("sheet instance props change", _));
}

/**
 *
 */
export const openSheetID = derived(sheetHistoryStack, (history) => history.at(-1) ?? null);

/**
 *
 */
export const previousSheetID = derived(sheetHistoryStack, (history) => history.at(-2) ?? null);

/**
 *
 *
 *
 */
const isSwitchingState = writable(false);
/*                                                                       */

/**
 *
 *
 */
export const noTransition = readonly(isSwitchingState);

/**
 *
 *
 *
 */
export const isSwitching = readonly(isSwitchingState);

/**
 *
 *
 */
const requestedCloseState = writable<CloseTypes | null>(null);
/*                                                     */
requestedCloseState.subscribe(() => requestedCloseState.set(null));

/**
 *
 *
 *
 */
export const requestedClose = readonly(requestedCloseState);
if (import.meta.env.DEV) {
  requestedClose.subscribe((closeType) => historyLog.info("requestedClose changed", closeType));
}

/**
 *
 *
 */
export const lastActiveDomElement = writable<HTMLElement | null>(null);
if (import.meta.env.DEV) {
  lastActiveDomElement.subscribe((el) => historyLog.info("lastActiveDomElement changed", el));
}

const sheetHistoryData = writable<[current: string[], previous: string[]]>([[], []]);
export const sheetHistory = readonly(sheetHistoryData);
if (import.meta.env.DEV) {
  sheetHistory.subscribe((history) =>
    historyLog.info("sheetHistory (current, previous", ...history),
  );
}

/*                                        */
let lastHistoryStack: string[] = [];
sheetHistoryStack.subscribe((historyStack) => {
  /*                */
  if (historyStack.length > 0 && lastHistoryStack.length === 0) {
    /*                                                                                             */
    lastActiveDomElement.set(document.activeElement as HTMLElement);
  }

  /*                                                */
  /*                                                                             */
  if (historyStack.length > 1 && lastHistoryStack.length === 1) {
    isSwitchingState.set(true);
  }

  /*                              */
  /*                                                                     */
  sheetHistoryData.set([[...historyStack], [...lastHistoryStack]]);
  lastHistoryStack = [...historyStack];
  /*                                                                       */
});

export function initHistoryStack(history: string[]): void {
  if (import.meta.env.DEV) historyLog.info("initialize history stack", history);
  lastHistoryStack = [...history];
  sheetHistoryStack.set(history);
  isSwitchingState.set(true);
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function next(sheetId: string, config?: Partial<Props>): void {
  /*                                                                */
  if (sheetId === get(openSheetID)) return;

  sheetHistoryStack.update((history) => [...history, sheetId]);

  if (!config) return;

  /*                                                                    */
  const unsub = openSheetInstance.subscribe((sheet) => {
    if (sheet?.id !== sheetId) return;
    applyProps(sheet, config);
    window.setTimeout(() => unsub(), 0);
  });
}

/**
 *
 *
 *
 *
 *
 */
export function back(): void {
  sheetHistoryStack.update((history) => {
    history.pop();

    /*                                                                          */
    /*                                                                       */
    if (history.length === 0) {
      requestedCloseState.set(CLOSE_TYPES[CLOSED_PROGRAMMATICALLY]);
    }

    return history;
  });
}

/**
 *
 *
 */
export function close(reason: CloseTypes = CLOSE_TYPES[CLOSED_PROGRAMMATICALLY]): void {
  requestedCloseState.set(reason);
  sheetHistoryStack.set([]);
}

function handleESCKey(e: KeyboardEvent): void {
  if (e.code === "Escape") {
    close(CLOSE_TYPES[CLOSED_WITH_ESCAPE]);
  }
}

/*                                                              */
isSheetOpen.subscribe((isOpen) => {
  if (isOpen) {
    /*                                                                             */
    document.addEventListener("keydown", handleESCKey);
  } else {
    /*                                                                                      */
    document.removeEventListener("keydown", handleESCKey);

    /*                                         */
    lastActiveDomElement.set(null);
    isSwitchingState.set(false);
    openSheetInstance.set(null);
  }
});

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
/*                                                                        */
export function useSheetHistory(host: HTMLOcSheetV1Element) {
  const hostSheetId = writable<string | null>(null);
  const hostOpenState = writable<boolean>(false);

  /*                                                                            */
  const isActiveSheet = derived(
    [openSheetID, hostSheetId],
    ([openId, hostId]) => !!hostId && openId === hostId,
  );

  /*                                                                                      */
  /*                                                              */
  isActiveSheet.subscribe((isActive) => {
    if (import.meta.env.DEV) {
      historyLog.info("sheet instane active", host.id, isActive);
    }

    if (isActive) openSheetInstance.set(host);
  });

  /**
 *
 *
 */
  let currentOpenState: boolean | undefined = false;

  return {
    /**
 *
 *
 */
    isActiveSheet,

    /**
 *
 */
    hostSheetId: readonly(hostSheetId),

    /**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
    syncInstanceOpenState(
      isOpen: boolean | undefined,
      sheetId: string | undefined,
      openSheetId: string | null,
    ) {
      if (import.meta.env.DEV)
        historyLog.debug(
          "syncInstanceOpenState",
          sheetId,
          "is open",
          isOpen,
          "openSheettId",
          openSheetId,
        );

      /*                                 */
      hostSheetId.set(sheetId ?? null);
      hostOpenState.set(isOpen ?? false);

      if (!sheetId) {
        if (import.meta.env.DEV) historyUseLog.info("sheet id is not set yet. skip processing");
        return currentOpenState;
      }

      /*                                                                         */
      /*                                                  */
      if (isOpen === currentOpenState) {
        currentOpenState = openSheetId === sheetId;
        return currentOpenState;
      }

      /*                                                            */
      /*                                                                                     */
      if (isOpen) {
        if (import.meta.env.DEV)
          historyUseLog
            .scope("syncInstanceOpenState")
            .info("Open sheet as requested by prop", sheetId);

        next(sheetId);
      } else if (openSheetId === sheetId) {
        if (import.meta.env.DEV)
          historyUseLog
            .scope("syncInstanceOpenState")
            .info("close sheet as requested by prop", sheetId);

        close(CLOSE_TYPES[CLOSED_PROGRAMMATICALLY]);
      }

      currentOpenState = isOpen;
      if (import.meta.env.DEV)
        historyUseLog.info("Current open state for sheet", sheetId, currentOpenState);
      return currentOpenState;
    },
  };
}

import { stringToDocumentFragment } from "@otto-ec/global-resources/dom";
import { executeInlineScripts } from "@otto-ec/global-resources/hardcore";
import { type Writable } from "svelte/store";
import { sheetScope } from "./utils";
import { getPropsFromContent, parseLegacyContent, updatePropsFromContent } from "./contentParser";
import type { OcSheetV1Props } from "../SheetV1.types.g";

import { failure, success, tryRun, type Result } from "../../../../common/utils/effect";

const log = /*           */ sheetScope.scope("external-content");

/**
 *
 *
 *
 *
 */
export function assertContentURL(url: string): Result<Error, string> {
  const [cause, parsed] = tryRun(() => new URL(url, window.location.href));
  if (cause) {
    return failure(new Error(`Could not parse given URL: ${url}`, { cause }));
  }

  /*                                                                     */
  if (
    /*                                              */
    parsed.hostname !== window.location.hostname &&
    !parsed.hostname.endsWith("otto.de")
  ) {
    return failure(new Error(`URL hostname is not allowed in: ${url}`));
  }

  return success(url);
}

/**
 *
 */
export class LoadError extends Error {
  /**
 *
 */
  name = "LoadError";

  /**
 *
 */
  status: number;

  /**
 *
 */
  response: Response;

  /**
 *
 *
 */
  errorContent?: string;

  constructor(message: string, response: Response, errorContent: string | undefined) {
    const msg = response ? `${message}: ${response.status}, ${response.url}` : message;
    super(msg);

    /*                                                */
    this.status = response.status || 300;
    this.response = response;
    this.errorContent = errorContent;
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export async function fetchFromUrl(
  url: string,
  options: { allowedCodes: number[] },
): Promise<Result<Error | LoadError, string>> {
  /*                                        */
  const controller = new AbortController();

  /*                          */
  const [error, response] = await tryRun(fetch, url, {
    redirect: "manual",
    headers: { Accept: "text/html" },
    signal: controller.signal,
  });

  if (error) {
    return failure(error);
  }

  /*                                                                      */
  if (response.ok && !!response.headers.get("Content-Type")?.includes("html")) {
    /*                     */
    return tryRun(() => response.text());
  }

  /*                   */
  if (
    /*                                                                                  */
    !!response.headers.get("Cache-Control")?.includes("no-transform") &&
    /*                                                                                  */
    (options.allowedCodes.length === 0 || options.allowedCodes.includes(response.status))
  ) {
    /*                                                   */
    return failure(new LoadError("Error Response", response, await response.text()));
  }

  /*                                                                         */
  controller.abort();
  return failure(new LoadError("Bad response", response, undefined));
}

/**
 *
 *
 */
export function applyContentToHost(
  host: HTMLOcSheetV1Element,
  externalContentApplied: Writable<boolean>,
  content: DocumentFragment | null,
): void {
  if (!content) return;

  /*                     */
  host.innerHTML = "";
  /*                         */
  host.appendChild(content);
  executeInlineScripts(host);

  /*                                                                 */
  /*                                          */
  externalContentApplied.set(true);
}

/*                                                                        */
export function useExternalContent(host: HTMLOcSheetV1Element) {
  const state = $state({
    /*                                                                      */
    isActive: false,
    /*                                                           */
    url: undefined as string | undefined,
    /*                                               */
    allowedCodes: [] as number[],
    /*                                                    */
    forbiddenProps: [] as string[],

    /*                                                                                  */
    validUrl: null as string | null,

    /*                                                                                */
    rawContent: null as string | null,
    /*                                                                 */
    rawError: null as Error | LoadError | null,

    /*                                                                                    */
    loadedContent: null as DocumentFragment | null,
    /*                                                                                        */
    errorContent: null as null | DocumentFragment,

    /*                                                                              */
    externalOptions: null as Partial<OcSheetV1Props> | null,
    /*                                                                 */
    externalContent: null as DocumentFragment | null,

    /*                                                                       */
    isApplied: false,
  });

  /*                                                                   */
  $effect(() => {
    if (!state.url) return;
    const [error, url] = assertContentURL(state.url);

    /*                       */
    if (error) {
      state.validUrl = null;
      state.rawError = error;
      return;
    }

    /*                                                         */
    state.rawContent = null;
    state.rawError = null;

    /*                                                                                */
    state.validUrl = import.meta.env.DEV && import.meta.env.STORYBOOK ? `/dev${url}` : url;
  });

  /*                                                           */
  /*                                                  */
  $effect(() => {
    if (!state.isActive || !state.validUrl || state.rawContent !== null) return;

    fetchFromUrl(state.validUrl, state).then(([error, raw]) => {
      if (error) {
        state.rawError = error;
        return;
      }

      /*                                                              */
      state.rawContent = raw;
    });
  });

  /*                                                   */
  $effect(() => {
    if (state.rawContent === null) {
      state.loadedContent = null;
      return;
    }

    /*                                                */
    state.loadedContent = stringToDocumentFragment(state.rawContent);
  });

  /*                                                           */
  $effect(() => {
    const error = state.rawError;
    if (!error) {
      state.errorContent = null;
      return;
    }

    log.error("Error loading external content", error);
    if (!(error instanceof LoadError) || !error.errorContent) return;
    /*                                      */
    state.errorContent = stringToDocumentFragment(error.errorContent);
  });

  /*                                               */
  $effect(() => {
    const content = state.loadedContent ?? state.errorContent;
    if (!content) {
      state.externalOptions = null;
      state.externalContent = null;
      return;
    }

    /*                                                              */
    state.externalOptions = getPropsFromContent(content);
    state.externalContent = parseLegacyContent(content);
  });

  /*                                 */
  $effect(() => {
    if (!state.externalOptions) return;
    updatePropsFromContent(host, state.forbiddenProps, state.externalOptions);
  });

  /*                                         */
  $effect(() => {
    if (!state.externalContent) return;

    /*                     */
    host.innerHTML = "";
    /*                         */
    host.appendChild(state.externalContent);
    executeInlineScripts(host);

    /*                                                                 */
    /*                                          */
    state.isApplied = true;
  });

  return state;
}

import { componentsScope } from "../../../common/utils/logger.js";

export const dialogScope = /*           */ componentsScope.scope("focused-dialog-v1");

export function getParamValue(key: string, paramsString: string): string | undefined {
  /*                                     */
  const searchParams = new URLSearchParams(paramsString);
  return searchParams.get(key) || undefined;
}

export function isSafeUrl(url: string, currentHost: string, currentProtocol: string): boolean {
  try {
    /*                                     */
    const { host, protocol, pathname } = new URL(url, window.location.href);

    /*                                                                             */
    if (/\/{2,}/g.test(pathname)) {
      return false;
    }

    const isSafe = host === currentHost && protocol === currentProtocol;

    if (!isSafe) {
      dialogScope.warn("is not safe", url);
    }
    return isSafe;
    /*                                                         */
  } catch (e) {
    dialogScope.warn(e);
    return false;
  }
}

<svelte:options
  customElement={{
    tag: "oc-icon-button-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      transparent: { type: "Boolean" },
      disabled: { type: "Boolean", reflect: true },
      iconType: { type: "String", attribute: "icon-type" },
      iconColor: { type: "String", attribute: "icon-color" },
      size: { type: "String", reflect: true },
      loading: { type: "Boolean" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
      elevated: { type: "Boolean" },
      elevation: { type: "String" },
      variant: { type: "String" },
    },
  }}
/>

<script lang="ts">
  import { fade } from "svelte/transition";
  import type { OcSpinnerV1Props } from "src/components/spinner";
  import type { OcIconV1Props } from "src/components/icon/v1/IconV1.types.g";
  import { useFullPathHrefAccessor } from "@otto-ec/otto-components-utils/use/full-path-href-accessor";
  import { InteractiveElement } from "../../../common/components/InteractiveElement";
  import type { Props } from "./IconButtonV1.types.js";

  let {
    variant = "default",
    elevation = "0",
    disabled = false,
    iconType,
    iconColor,
    size = "50",
    loading = false,
    ocAriaLabel,
    href = undefined,
    base64Href = undefined,
    role = null,
    transparent = false,
    elevated = false,
  }: Props & {
    role?: string | null;
    tabindex?: number;
  } = $props();

  const Host = $host<HTMLElement>();

  useFullPathHrefAccessor(
    Host,
    () => href,
    (v) => {
      href = v;
    },
  );

  let computedVariant = $derived(transparent ? "transparent" : (variant ?? "default"));
  let computedElevation = $derived(elevated ? "200" : (elevation ?? "0"));

  const spinnerVariant: Record<Exclude<typeof variant, undefined>, OcSpinnerV1Props["variant"]> = {
    default: "default",
    transparent: "default",
    inverted: "inverted",
  };

  const spinnerSize: Record<Exclude<typeof size, undefined>, OcSpinnerV1Props["size"]> = {
    "100": "100",
    "50": "50",
    "25": "50",
  };

  const iconSize: Record<Exclude<typeof size, undefined>, OcIconV1Props["size"]> = {
    "100": "100",
    "50": "100",
    "25": "50",
  };

  const handleClick = (event: MouseEvent | KeyboardEvent) => {
    if (loading) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  Host.addEventListener("click", handleClick);
</script>

<!-- eslint-disable no-nested-ternary -->
<InteractiveElement
  asButton={role !== "none"}
  bind:href
  bind:base64Href
  class={`icon-button icon-button--size-${size} icon-button--elevation-${computedElevation} icon-button--variant-${computedVariant} ${loading ? "icon-button--is-loading" : ""}`}
  aria-label={ocAriaLabel}
  {disabled}
>
  {#if loading}
    <oc-spinner-v1
      in:fade={{ duration: 250 }}
      class="icon-button__icon"
      variant={spinnerVariant[variant ?? "default"]}
      size={spinnerSize[size ?? "50"]}
    ></oc-spinner-v1>
  {:else}
    <oc-icon-v1
      aria-hidden="true"
      class="icon-button__icon"
      class:icon-button__icon--disabled={disabled}
      size={iconSize[size ?? "50"]}
      type={iconType}
      color={disabled ? undefined : iconColor}
    ></oc-icon-v1>
  {/if}
</InteractiveElement>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    @include mixins.no-tap-highlight();
    display: inline-block;
    outline: none;
  }

  /*                 */
  .icon-button {
    display: block;
    position: relative;
    border-radius: 50%;
    border: none;
    height: 32px;
    width: 32px;
    cursor: pointer;

    /*                                                */
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      min-width: tokens.$oc-component-icon-button-50-hitbox-size;
      min-height: tokens.$oc-component-icon-button-50-hitbox-size;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
    }

    & {
      @include mixins.focus-styles();
    }

    &:not(.icon-button--is-loading) {
      &:active:not(:disabled) {
        background-color: tokens.$oc-component-icon-button-default-background-color-active;
      }
    }

    &--size-100 {
      width: tokens.$oc-component-icon-button-100-background-size;
      height: tokens.$oc-component-icon-button-100-background-size;
    }

    &--size-25 {
      width: tokens.$oc-component-icon-button-25-background-size;
      height: tokens.$oc-component-icon-button-25-background-size;

      &:before {
        min-width: tokens.$oc-component-icon-button-25-hitbox-size;
        min-height: tokens.$oc-component-icon-button-25-hitbox-size;
      }
    }

    &--variant-default {
      background-color: tokens.$oc-component-icon-button-default-background-color;

      &:not(.icon-button--is-loading):not(:disabled) {
        @media (hover: hover) {
          &:hover {
            background-color: tokens.$oc-component-icon-button-default-background-color-hover;
          }

          &:active {
            background-color: tokens.$oc-component-icon-button-default-background-color-active;
          }
        }
      }
    }

    &--variant-transparent {
      background-color: tokens.$oc-component-icon-button-transparent-background-color;

      &:not(.icon-button--is-loading):not(:disabled) {
        @media (hover: hover) {
          &:hover {
            background-color: tokens.$oc-component-icon-button-transparent-background-color-hover;
          }

          &:active {
            background-color: tokens.$oc-component-icon-button-transparent-background-color-active;
          }
        }
      }
    }

    &--variant-inverted {
      background-color: tokens.$oc-component-icon-button-inverted-background-color;

      &:not(.icon-button--is-loading):not(:disabled) {
        @media (hover: hover) {
          &:hover {
            background-color: tokens.$oc-component-icon-button-inverted-background-color-hover;
          }

          &:active {
            background-color: tokens.$oc-component-icon-button-inverted-background-color-active;
          }
        }
      }

      .icon-button__icon:not(.icon-button__icon--disabled) {
        fill: tokens.$oc-component-icon-button-inverted-icon-color;
      }
    }

    &--elevation-100 {
      filter: tokens.$oc-base-shadow-100;
    }

    &--elevation-200 {
      filter: tokens.$oc-base-shadow-200;
    }

    &--elevation-300 {
      filter: tokens.$oc-base-shadow-300;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: tokens.$oc-component-icon-button-disabled-background-color;
    }

    &__icon {
      fill: #222222;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);

      &--disabled {
        fill: tokens.$oc-component-icon-button-disabled-icon-color;
      }
    }

    &--is-loading {
      cursor: progress;
    }
  }
</style>

<svelte:options
  customElement={{
    tag: "oc-carousel-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      arrowButtonPosition: { type: "String", attribute: "arrow-button-position" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { onMount } from "svelte";
  import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";
  import { refireScrollEvent } from "../../../common/actions/refireScrollEvent";
  import { ArrowButtonV1 } from "../../../common/components/ArrowButton";
  import PaginationDotsV1 from "../../../common/components/PaginationDotsV1/PaginationDotsV1.svelte";

  import type { Events, Props } from "./CarouselV1.types";

  type CarouselItemHTMLElement = {
    isVisible?: boolean;
  } & HTMLElement;

  let { ocAriaLabel, arrowButtonPosition, visibleItems = [] }: Props = $props();

  const Host = $host<HTMLElement>();

  const dispatch = useEventDispatcher<Events>(Host);

  let slideCount = $state(Host.childElementCount);
  let carouselStage = $state<HTMLElement>();
  let prevCurrentItemIndex = 0;
  let currentItemIndex = $state(0);
  let items = $state(Array.from(Host.children) as CarouselItemHTMLElement[]);

  /*                                                        */
  let hideLeftArrow = $state(true);
  let hideRightArrow = $state(true);

  let intersectingTimer = -1;

  /*                                             */
  const intersectingFinished = () => {
    if (currentItemIndex === prevCurrentItemIndex) return;

    dispatch("oc-item-visibility", {
      visibleItems: items.filter((item) => item.isVisible),
      scrollDirection: currentItemIndex > prevCurrentItemIndex ? "right" : "left",
    });

    prevCurrentItemIndex = currentItemIndex;
  };

  const carouselIntersecting = () => {
    clearTimeout(intersectingTimer);

    intersectingTimer = window.setTimeout(intersectingFinished, 500);
  };

  onMount(() => {
    const intersectionObserverOptions: IntersectionObserverInit = {
      root: carouselStage,
      threshold: 0.5,
    };

    let lastIntersectingItem: CarouselItemHTMLElement | null = null;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target as CarouselItemHTMLElement;
        target.isVisible = entry.isIntersecting;
        if (entry.isIntersecting) {
          lastIntersectingItem = target;
        }
      });

      visibleItems = items.filter((item) => item.isVisible);

      currentItemIndex = items.findIndex((i) => i.isVisible);
      if (currentItemIndex === -1) {
        /*                                                            */
        currentItemIndex = items.findIndex((i) => i === lastIntersectingItem);
      }

      hideLeftArrow = currentItemIndex === 0;
      hideRightArrow = currentItemIndex === slideCount - 1;

      carouselIntersecting();
    }, intersectionObserverOptions);

    /*                 */
    Array.from(Host.children).forEach((child) => {
      observer.observe(child);
    });

    slideCount = Host.childElementCount;
    new MutationObserver((mutations) => {
      mutations.forEach(({ removedNodes, addedNodes }) => {
        addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) observer.observe(node);
        });
        removedNodes.forEach((node) => {
          if (node instanceof HTMLElement) observer.unobserve(node);
        });

        items = Array.from(Host.children) as CarouselItemHTMLElement[];
      });
      slideCount = Host.childElementCount;
    }).observe(Host, { childList: true });

    return () => {
      observer.disconnect();
    };
  });

  const moveTo = (target: number) => {
    if (target >= 0 && target <= slideCount - 1) {
      const item = Host.children.item(target);
      if (item instanceof HTMLElement) {
        carouselStage!.scrollLeft = item?.offsetLeft;
      }
    }
  };
</script>

<div class="carousel" style:--arrow-button-position={arrowButtonPosition}>
  <section
    class="carousel__stage"
    bind:this={carouselStage}
    use:refireScrollEvent={Host}
    aria-label={ocAriaLabel}
    data-oc-floating-focus-v1-target
  >
    <slot />
  </section>
  <ArrowButtonV1
    hidden={hideLeftArrow}
    direction="left"
    onclick={() => moveTo(currentItemIndex - 1)}
  />
  <ArrowButtonV1
    hidden={hideRightArrow}
    direction="right"
    onclick={() => moveTo(currentItemIndex + 1)}
  />
</div>

<PaginationDotsV1
  amountDots={slideCount}
  slides={items}
  selectedDot={currentItemIndex}
  {moveTo}
  isCarousel
/>

<style lang="scss" global>
  @use "@otto-ec/otto-components-utils/scss/mixins";
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "../../floating-focus/v1/FloatingFocusV1.global";

  /*                                         */
  ::slotted(*) {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    flex: 0 0 100%;
  }

  :host {
    /*        */
    --arrow-button-position: 50%;

    @include mixins.no-tap-highlight();
    display: block;
  }

  .carousel {
    position: relative;

    &__stage {
      display: flex;
      gap: tokens.$oc-component-carousel-stage-gap-x;
      overflow-x: auto;
      scrollbar-width: none; /*     */
      scroll-snap-type: x mandatory;

      /*                                  */
      margin: -4px;
      padding: 4px;

      /*                                    */
      @supports (scroll-snap-type: x mandatory) {
        scroll-snap-type: x mandatory;
      }

      /*                                    */
      @supports (scroll-behavior: smooth) {
        scroll-behavior: smooth;
      }

      /*                                    */
      @supports (scroll-behavior: smooth) and (scroll-snap-type: x mandatory) {
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
      }

      /*                                            */
      @media (prefers-reduced-motion) {
        scroll-behavior: auto;
      }

      &::-webkit-scrollbar {
        display: none; /*                */
      }
    }
  }
</style>

<script lang="ts">
  import { isSmallDot } from "./isSmallDot";

  const itemTypes = { video: "video" };
  interface Props {
    selectedDot?: number;
    amountDots?: number;
    slides?: any;
    isCarousel?: boolean;
    moveTo: (index: number) => void;
  }

  let {
    selectedDot = 0,
    amountDots = 0,
    slides = [],
    isCarousel = false,
    moveTo,
  }: Props = $props();

  let transform = $state("translateX(0)");

  const scrollWidth = 16;

  $effect(() => {
    if (selectedDot <= 3) {
      transform = `translateX(0)`;
    } else if (selectedDot >= amountDots - 4) {
      transform = `translateX(-${scrollWidth * (amountDots - 7)}px)`;
    } else {
      transform = `translateX(-${scrollWidth * (selectedDot - 3)}px)`;
    }
  });
</script>

{#if amountDots > 1}
  <div class="paginationdots" aria-hidden="true">
    <div class="paginationdots__wrapper" style:transform>
      <!--      eslint-disable-next-line-->
      {#if isCarousel}
        {#each slides as _, key}
          <div
            role="none"
            class="paginationdots__item"
            class:paginationdots__item--selected={selectedDot === key}
            class:paginationdots__item--small={isSmallDot(key, selectedDot, amountDots)}
            class:paginationdots__item--video={_.dataset["ocCarouselV1.itemType"] ===
              itemTypes.video}
            class:paginationdots__item--video--selected={_.dataset["ocCarouselV1.itemType"] ===
              itemTypes.video && selectedDot === key}
            onclick={() => moveTo(key)}
          ></div>
        {/each}
      {:else}
        {#each Array(amountDots) as _, key}
          <div
            role="none"
            class="paginationdots__item"
            class:paginationdots__item--selected={selectedDot === key}
            class:paginationdots__item--small={isSmallDot(key, selectedDot, amountDots)}
            onclick={() => moveTo(key)}
          ></div>
        {/each}
      {/if}
    </div>
  </div>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  .paginationdots {
    width: calc(
      7 * tokens.$oc-component-pagination-dot-size-default + 7 *
        tokens.$oc-component-pagination-dot-gap-x
    ); /*                                  */
    overflow-x: hidden;
    display: flex;
    margin: 8px auto 0;

    &__wrapper {
      display: flex;
      transition: transform 0.2s ease-in-out;
    }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: tokens.$oc-component-pagination-dot-size-default;
      height: tokens.$oc-component-pagination-dot-size-default;
      padding: 0 calc(tokens.$oc-component-pagination-dot-gap-x / 2);
      box-sizing: content-box;
      border: none;
      outline: none;
      background: none;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-color: tokens.$oc-component-pagination-dot-default;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
      }

      &:not(&--selected, &--video) {
        cursor: pointer;

        @media (hover: hover) {
          &:hover:after {
            background-color: tokens.$oc-component-pagination-dot-hover;
          }
        }

        &:active:after {
          background-color: tokens.$oc-component-pagination-dot-active;
        }
      }

      &--selected:after {
        background-color: tokens.$oc-component-pagination-dot-selected;
      }

      &--small:after {
        width: tokens.$oc-component-pagination-dot-size-small;
        height: tokens.$oc-component-pagination-dot-size-small;
      }

      &--video {
        &::after {
          content: "";
          background-color: transparent;
          border-bottom: tokens.$oc-component-pagination-dot-size-small solid transparent;
          border-left: 8px solid tokens.$oc-component-pagination-dot-default;
          border-top: tokens.$oc-component-pagination-dot-size-small solid transparent;
          border-radius: 0;
          height: 0;
          width: 0;
        }

        @media (hover: hover) {
          cursor: pointer;

          &:hover:after {
            border-left-color: tokens.$oc-component-pagination-dot-hover;
          }
        }

        &:active:after {
          border-left-color: tokens.$oc-component-pagination-dot-active;
        }

        &--selected {
          &::after {
            border-left-color: tokens.$oc-component-pagination-dot-selected;
          }
        }
      }
    }
  }
</style>

<svelte:options
  customElement={{
    tag: "oc-logo-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      logo: { type: "String" },
      asButton: { type: "Boolean", attribute: "as-button" },
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      fillParent: { type: "Boolean", attribute: "fill-parent" },
    },
  }}
/>

<script lang="ts">
  import { useFullPathHrefAccessor } from "@otto-ec/otto-components-utils/use/full-path-href-accessor";
  import { InteractiveElement } from "../../../common/components/InteractiveElement";

  import type { Props } from "./LogoV1.types.js";
  import { logoMap } from "../preCalcedLogoDimensions";

  let {
    logo,
    asButton = false,
    href = undefined,
    base64Href = undefined,
    ocAriaLabel = undefined,
    fillParent = false,
  }: Props = $props();

  const Host = $host<HTMLElement>();

  useFullPathHrefAccessor(
    Host,
    () => href,
    (v) => {
      href = v;
    },
  );

  let logoIdentifier = $derived(`pl_logo_${logo}` as keyof typeof logoMap);
  let svgUrl = $derived(`/assets-static/icons/${logoIdentifier}.svg#${logoIdentifier}`);

  let isAriaHidden = $derived(!ocAriaLabel);
</script>

{#if logo}
  <InteractiveElement
    {asButton}
    bind:href
    {base64Href}
    class="logo {fillParent ? 'logo--fill-parent' : ''}"
    style="--width: {logoMap[logoIdentifier]?.width}px; --height: {logoMap[logoIdentifier]
      ?.height}px; --aspect-ratio: {logoMap[logoIdentifier]?.width /
      logoMap[logoIdentifier]?.height}"
    interactiveClass="logo--interactive"
  >
    <svg role="img" width="100%" height="100%" aria-hidden={isAriaHidden} aria-label={ocAriaLabel}>
      <use href={svgUrl} />
    </svg>
  </InteractiveElement>
{/if}

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    display: inline-block;
  }

  .logo {
    position: relative;
    display: block;
    padding: 0;
    border: none;
    outline: 0 none;

    height: var(--height);
    width: var(--width);

    & {
      @include mixins.focus-styles(0.01px);
      @include mixins.no-tap-highlight();
    }

    &--interactive {
      cursor: pointer;

      $offset: calc(-1 * tokens.$oc-component-interactive-image-overlay-offset);

      /*          */
      @media (hover: hover) {
        &:hover {
          &::before {
            content: "";
            position: absolute;
            top: $offset;
            left: $offset;
            right: $offset;
            bottom: $offset;
            border-radius: tokens.$oc-component-interactive-image-overlay-border-radius;
            pointer-events: none;
            background-color: tokens.$oc-component-interactive-image-overlay-hover;
          }
        }
      }

      /*           */
      &:active {
        &::before {
          content: "";
          position: absolute;
          top: $offset;
          left: $offset;
          right: $offset;
          bottom: $offset;
          border-radius: tokens.$oc-component-interactive-image-overlay-border-radius;
          pointer-events: none;
          background-color: tokens.$oc-component-interactive-image-overlay-active;
        }
      }
    }

    /*                                           */
    &--fill-parent {
      width: 100%;
      height: 100%;
      aspect-ratio: var(--aspect-ratio);
    }

    > svg {
      display: block;
      position: relative;
    }
  }
</style>

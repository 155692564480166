<svelte:options
  customElement={{
    tag: "oc-focused-dialog-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      headline: { type: "String", reflect: true },
      closeUrl: { type: "String", attribute: "close-url", reflect: true },
      prevUrl: { type: "String", attribute: "prev-url", reflect: true },
    },
  }}
/>

<script lang="ts">
  import { onMount } from "svelte";
  import type { Props } from "./FocusedDialogV1.types.js";
  import { dialogScope, getParamValue, isSafeUrl } from "./FocusedDialogV1.utils";

  const prefix = "oc-focused-dialog-v1";
  const base64HrefAttribute = "base64Href";

  let {
    closeUrl = getParamValue(`${prefix}.close-url`, window.location.search),
    prevUrl = getParamValue(`${prefix}.prev-url`, window.location.search),

    headline = "",
  }: Props = $props();

  const Host = $host();

  const { host: hostName, protocol } = window.location;

  $effect(() => {
    if (prevUrl && isSafeUrl(prevUrl, hostName, protocol)) {
      const prevQuery = `[slot='actions'] [data-${prefix}\\.prev-url]`;
      const prevElements = Array.from(
        Host.querySelectorAll(prevQuery) as NodeListOf<HTMLOcButtonV1Element | HTMLAnchorElement>,
      );

      prevElements.forEach((el) => {
        if (el.hasAttribute(base64HrefAttribute)) {
          el.setAttribute(base64HrefAttribute, window.btoa(prevUrl));
        } else {
          el.href = prevUrl;
        }
      });
    }
  });

  /*                                                        */
  $effect(() => {
    if (closeUrl && isSafeUrl(closeUrl, hostName, protocol)) {
      const closeUrlQuery = `[slot='actions'] [data-${prefix}\\.add-close-url][href]`;
      const closeUrlElements = Array.from(
        Host.querySelectorAll(closeUrlQuery) as NodeListOf<
          HTMLOcButtonV1Element | HTMLAnchorElement
        >,
      );

      closeUrlElements.forEach((el) => {
        try {
          if (el.hasAttribute(base64HrefAttribute)) {
            const newCloseUrl = new URL(window.atob(el.getAttribute(base64HrefAttribute) || ""));
            newCloseUrl.searchParams.set("close-url", closeUrl);
            el.setAttribute(base64HrefAttribute, window.btoa(newCloseUrl.href));
          } else {
            const { href } = el;

            const newCloseUrl = new URL(href as string);
            newCloseUrl.searchParams.set("close-url", closeUrl);
            el.setAttribute("href", newCloseUrl.href);
          }
        } catch (e) {
          dialogScope.warn("invalid url %s", e);
        }
      });
    }
  });

  /*                                                         */
  const stuckState = $state({ title: false, actions: false });
  /*                                                                     */
  const stuckElementStateMap = new WeakMap<Element, keyof typeof stuckState>();

  const observer = new IntersectionObserver(
    ([{ target, intersectionRatio }]) => {
      const stateKey = stuckElementStateMap.get(target)!;
      stuckState[stateKey] = intersectionRatio < 1;
    },
    { threshold: [1] },
  );

  onMount(() => {
    /**
 *
 *
 */
    (["title", "actions"] as const)
      .map((k) => [k, Host.shadowRoot!.querySelector(`.${k}`)] as const)
      .forEach(([k, e]) => {
        if (!e) return; /*                             */
        /*                                                                                */

        stuckElementStateMap.set(e, k);
        /*                       */
        observer.observe(e);
      });

    /*                  */
    return () => {
      observer.disconnect();
    };
  });
</script>

<div class="focused-dialog">
  <div class="title" class:title--stuck={stuckState.title}>
    <div class="title__prev">
      {#if prevUrl && isSafeUrl(prevUrl, hostName, protocol)}
        <oc-icon-button-v2 ocAriaLabel="zurück" href={prevUrl} icon="arrow-left"
        ></oc-icon-button-v2>
      {/if}
    </div>
    <slot name="headline">
      <h1 class="title__headline">{headline}</h1>
    </slot>
    <div class="title__close">
      {#if closeUrl && isSafeUrl(closeUrl, hostName, protocol)}
        <oc-icon-button-v2 icon="close" ocAriaLabel="schließen" href={closeUrl}></oc-icon-button-v2>
      {/if}
    </div>
  </div>

  <section class="content-area">
    <slot />
  </section>
  <div class="actions" class:actions--stuck={stuckState.actions}>
    <slot name="actions" />
  </div>
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins" as mixins;

  :host {
    --content-background-color: unset;
    --content-padding: unset;
  }

  .focused-dialog {
    background: tokens.$oc-component-focused-dialog-background-color;
    height: 100%;

    .title {
      display: flex;
      position: sticky;
      top: -2px;
      justify-content: space-between;
      background-color: tokens.$oc-component-focused-dialog-header-background-color;
      padding: 24px 16px 16px;

      &--stuck {
        border-bottom: tokens.$oc-component-focused-dialog-header-conditional-border-bottom-width
          solid tokens.$oc-component-focused-dialog-header-conditional-border-bottom-color;
      }

      &__prev,
      &__close {
        /**
 *
 *
 */
        @include mixins.breakpoint-from-m($to: false, $containerFirst: false) {
          width: 32px;
        }
      }

      &__headline {
        text-align: left;
        flex-grow: 1;
        margin: 0;
        color: tokens.$oc-component-focused-dialog-header-title-color;
        line-break: anywhere;
        font: tokens.$oc-component-focused-dialog-header-title-font;
        @include mixins.breakpoint-from-m($to: false, $containerFirst: false) {
          text-align: center;
        }
      }
    }
    .content-area {
      padding: var(--content-padding, 16px);
      background-color: var(
        --content-background-color,
        tokens.$oc-component-focused-dialog-content-background-color
      );
    }

    .actions {
      background-color: tokens.$oc-component-focused-dialog-action-bar-background-color;
      padding: tokens.$oc-component-focused-dialog-action-bar-spacing-top
        tokens.$oc-component-focused-dialog-action-bar-spacing-x
        tokens.$oc-component-focused-dialog-action-bar-spacing-bottom
        tokens.$oc-component-focused-dialog-action-bar-spacing-x;
      bottom: -2px;
      position: sticky;

      &--stuck {
        border-top: tokens.$oc-component-focused-dialog-action-bar-conditional-border-top-width
          solid tokens.$oc-component-focused-dialog-action-bar-conditional-border-top-color;
      }
    }
  }
</style>

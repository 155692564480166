<svelte:options
  customElement={{
    tag: "oc-chip-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({
      delegateFocus: true,
      formAssociated: true,
    }),
    props: {
      inverted: { type: "Boolean" },
      size: { type: "String" },
      label: { type: "String" },
      removable: { type: "Boolean" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" },
      checked: { type: "Boolean" }, /*                                     */
      icon: { type: "String" },
      showCheckIcon: { type: "Boolean", attribute: "show-check-icon" },
      singleSelection: { type: "Boolean", attribute: "single-selection" },
      noSelection: { type: "Boolean", attribute: "no-selection" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";

  import type { Events, Props } from "./ChipV1.types";
  import { useSlots } from "../../../common/utils/useSlots.svelte";

  let {
    inverted = false,
    size = "100",
    removable = false,
    disabled = false,
    name = undefined,
    value = "on",
    checked = false,
    showCheckIcon = false,
    icon = undefined,
    singleSelection = false,
    noSelection = false,
    label = "",
    ocAriaLabel = undefined,
    internals,
  }: Props & {
    internals: ElementInternals;
  } = $props();

  const Host = $host();
  const slots = useSlots(Host);

  let dispatch = useEventDispatcher<Events>(Host);

  let leftIcon = $derived(checked && showCheckIcon ? "check" : icon);

  export function resetForm() {
    /*                            */
    if (!removable) {
      checked = Host.hasAttribute("checked");
    }
  }

  $effect(() => {
    internals.setFormValue(checked ? value : null);
  });
  /*                                         */
  let role = $derived(removable ? "button" : singleSelection ? "radio" : "checkbox");

  const onCheckToggle = (event: MouseEvent | KeyboardEvent) => {
    if (disabled) return;

    if (removable) {
      if (
        (event instanceof KeyboardEvent && ["Space", "Enter"].includes(event.code)) ||
        event.type === "click"
      ) {
        dispatch("oc-remove");
      }
    } else if (
      (event instanceof KeyboardEvent && event.code === "Space") ||
      event.type === "click"
    ) {
      /*                                        */
      if (singleSelection && checked) return;
      checked = !checked;
    }

    /*                             */
    if (event instanceof KeyboardEvent && event.code === "Space") {
      event.preventDefault();
    }
  };

  /*                      */
  $effect(() => {
    if (singleSelection && name && checked && internals) {
      document
        .querySelectorAll<HTMLOcChipV1Element>(`oc-chip-v1[name="${name}"]`)
        .forEach((chip) => {
          const isSelf = chip.value === value;
          if (!isSelf && chip.checked) {
            chip.checked = false;
          }
        });
    }
  });
</script>

<!-- svelte-ignore a11y_no_noninteractive_tabindex -->
<div
  class="chip chip--size-{size}"
  class:chip--inverted={inverted}
  onclick={!noSelection ? onCheckToggle : undefined}
  onkeydown={!noSelection ? onCheckToggle : undefined}
  {role}
  tabindex={disabled ? -1 : 0}
  aria-label={slots.default || label ? ocAriaLabel : "Chip ohne Inhalt"}
  aria-disabled={disabled}
  aria-checked={removable ? undefined : `${checked}`}
>
  {#if slots.image}
    <div class="chip__image"><slot name="image" /></div>
  {/if}

  <label class="chip__label">
    {#if leftIcon}
      <oc-icon-v1
        class="chip__icon chip__icon--{size}"
        size={size === "100" ? "50" : "100"}
        type={leftIcon}
      ></oc-icon-v1>
    {/if}

    {#if slots.default}
      <span class="chip__label__text"><slot /></span>
    {:else if label}
      <!-- if you use the prop label, the chip does not change it's width when selected 😍 -->
      <span class="chip__label__text" data-label={label}>{label}</span>
    {/if}

    {#if removable}
      <span class="chip__aria-remove">entfernen</span>
      <oc-icon-v1
        class="chip__icon chip__icon--removable chip__icon--{size}"
        size={size === "100" ? "50" : "100"}
        type="close"
      ></oc-icon-v1>
    {/if}
  </label>
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    @include mixins.no-tap-highlight();
    display: inline-block;
  }

  .chip {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: tokens.$oc-component-chip-min-width;
    background-color: tokens.$oc-component-chip-default-background-color;
    color: tokens.$oc-component-chip-text-color;
    cursor: pointer;
    user-select: none;
    padding: tokens.$oc-base-dimension-relative-4;
    @include mixins.focus-styles(20px);

    @media (hover: hover) {
      &:hover {
        background-color: tokens.$oc-component-chip-default-background-color-hover;
      }
    }

    &:active {
      background-color: tokens.$oc-component-chip-default-background-color-active;
    }

    &__label {
      pointer-events: none;
      user-select: none;
      display: flex;
      align-items: center;
      gap: tokens.$oc-component-chip-100-gap-x;
      font-size: tokens.$oc-component-chip-100-font;

      &:first-child {
        padding-left: 8px;
      }
      &:last-child {
        padding-right: 8px;
      }

      /**
 *
 *
 */

      &__text {
        text-align: center;

        &::after {
          display: block;
          content: attr(data-label);
          font-weight: tokens.$oc-component-chip-selected-font-weight;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }
    }
    &__image {
      overflow: hidden;
      ::slotted(*) {
        max-width: 100%;
        height: 100%;
      }
    }

    &--size-100 {
      border-radius: 1.25rem;
      gap: tokens.$oc-component-chip-100-gap-x;
      font: tokens.$oc-component-chip-100-font;
      /*                                                                                                                                        */
      height: tokens.$oc-base-dimension-relative-32;

      .chip__image {
        border-radius: 1rem;
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    /*          */
    &--size-200 {
      border-radius: 1.5rem;
      gap: tokens.$oc-component-chip-200-gap-x;
      font: tokens.$oc-component-chip-200-font;
      height: tokens.$oc-base-dimension-relative-48;
      @include mixins.focus-styles(24px);

      .chip__label {
        &__text {
          padding: 0 4px;
        }
      }

      .chip__image {
        border-radius: 1.25rem;
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    &[aria-checked="true"] {
      background-color: tokens.$oc-component-chip-selected-background-color;
      font-weight: tokens.$oc-component-chip-selected-font-weight;

      @media (hover: hover) {
        &:hover {
          background-color: tokens.$oc-component-chip-selected-background-color-hover;
        }
      }

      &:active {
        background-color: tokens.$oc-component-chip-selected-background-color-active;
      }
    }

    &[aria-disabled="true"] {
      background-color: tokens.$oc-component-chip-disabled-background-color;
      color: tokens.$oc-component-chip-disabled-icon-color;
      pointer-events: none;

      &[aria-checked] {
        font-weight: normal;
      }

      .chip__icon {
        fill: tokens.$oc-component-chip-disabled-icon-color;
      }
    }

    &__icon {
      height: tokens.$oc-component-chip-100-icon-size;
      width: tokens.$oc-component-chip-100-icon-size;
      pointer-events: none;

      &--200 {
        align-self: auto;
        height: tokens.$oc-component-chip-200-icon-size;
        width: tokens.$oc-component-chip-200-icon-size;
      }
    }

    &--inverted {
      background-color: tokens.$oc-component-chip-inverted-background-color;

      @media (hover: hover) {
        &:hover {
          background-color: tokens.$oc-component-chip-inverted-background-color-hover;
        }
      }

      &:active {
        background-color: tokens.$oc-component-chip-inverted-background-color-active;
      }
    }

    &__aria-remove {
      @include mixins.visually-hidden();
    }
  }
</style>
